.FormSelect {
  border-radius: 7rem !important;
  padding: 0.8rem;
  outline: none;
}

.FormSelect:focus {
  outline: none !important;
}

.FormSelect {
  color: #1e0e62;
}

.buttonForm2 {
  border-radius: 7rem;
  padding: 0.8rem;
}

.form2InpText {
  font-size: 13px !important;
}

.form2Heading {
  font-weight: bolder;
  /* font-size: 2.5rem; */
}

.thankyoumsg {
  font-size: 10px !important;
  color: gray;
  margin: 1rem 0 !important;
}

.ratingpara {
  font-size: 13px;
}

.disableddiv {
  pointer-events: none;
  opacity: 0.4;
}

.inpForm1 {
  outline: none;
  border: none;
  text-indent: 10px;
  margin-left: 8px;
}

.inpDate {
  padding: 0.8rem;
  text-indent: 5px;
  border-radius: 7rem !important;
  background-image: none !important;
  color: #1e0e62;
}

.inpDate::before {
  content: attr(placeholder);
  position: absolute;
  color: #1e0e62;
}

.inpDate:focus,
.inpDate:valid {
  color: #1e0e62;
}

.inpDate:focus::before,
.inpDate:valid::before {
  content: '';
}

.inpIcon {
  padding: 1rem;
  margin-top: 6px;
}

.inpText {
  font-size: 13px !important;
}

.inpContainer {
  display: flex;
  align-items: center;
  /* padding: 0.5rem; */
  border: 1px solid gray;
  border-radius: 5rem;
  opacity: 0.5;
  height: 3rem !important;
}

.inpTextAreaContainer {
  display: flex;
  /* padding: 0.5rem; */
  border: 1px solid gray;
  border-radius: 1rem;
  opacity: 0.5;
  height: 7rem !important;
  overflow: hidden;
  resize: none;
}

.inpError {
  border: 2px solid red;
  border-radius: 5rem;
  opacity: 0.5;
}

.inpContainer input::placeholder {
  margin-bottom: 0.4rem;
}
